import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.css';
import Login, { Loginc } from './Projectfile/Login';
import {Box} from '@mui/material'

import Dashboard from './Projectfile/Projectsubpage/Dashboard';
import { Dashboardc } from './Projectfile/Projectsubpage/Dashboard';
import Activities, { Activitiesc } from './Projectfile/Projectsubpage/Activities';
import User, { Userc } from './Projectfile/Projectsubpage/User'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


import {Corporatec} from './corporate/Corporate';
import Addcorporate, { Addcorporatec } from './corporate/Addcorporate';
import { Statec } from './resion/State';
import { Cityc } from './resion/City';
import { Areac } from './resion/Area';
import Addstate, { Addstatec } from './resion/Addstate';
import Addarea, { Addareac } from './resion/Addarea'
import Addcity, { Addcityc } from './resion/Addcity'
import { Adduserc } from './Projectfile/Projectsubpage/Adduser';
import { Productlistc } from './product/Productlist';
import { Addproductc } from './product/Addproduct';
import { Componentc } from './component/Component';
import { Addcomponentc } from './component/Addcomponent';
import { Unitc } from './unit_management/Unit';
import { Addunitc } from './unit_management/Addunit';
import { Addactivitiesc } from './Projectfile/Projectsubpage/Addactivities';
import { Taskc } from './Task/Task';
import { Addtaskc } from './Task/Addtask';
import Error from './Error';
import { useNavigate,useLocation,useMatch } from 'react-router-dom';
import { Stockc } from './stockmanagement/Stock';
import { Stocklistc } from './stockmanagement/Stocklist';
import Exelspreadsheet from './Exelspreadsheet';
import Multitask, { Multitaskc } from './Task/Multitask';
function App() {

const [islogin,setIslogin] = useState(sessionStorage.getItem('isloginok'))
const [token,setToken] = useState(sessionStorage.getItem('token'))
useEffect(()=>{



},[])



function st(){
  sessionStorage.setItem('isloginok','true')
  
}

function cl(){
  sessionStorage.setItem('isloginok','false')
 
}

  return (
<Box>
    <RouterProvider router={
createBrowserRouter([
  {
    path: "/",
    element: <Login  st={st}  cl={cl}/>,
  },
  {
    path: "/dashboardc",
    element:islogin=="true"? <Dashboardc/>:<Login/>,
  },
  {
    path: "/activities",
    element: islogin=="true"?<Activitiesc/>:<Error/>
  },
  {
    path: "/add_activity",
    element:islogin=="true"?<Addactivitiesc/>:<Error/>
  },


  ////////////////////
  {
    path: "/users",
    element:islogin=="true"?<Userc/> : <Error/>
  },
  {
    path: "/users/add",
    element:islogin=="true"? <Adduserc/>:<Error/>
  },
  ///// corporates section
  {
    path: "/corporate",
    element: islogin=="true"?<Corporatec/> :<Error/>
  },
  {
    path: "/corporate/addcorporate",
    element:islogin=="true"? <Addcorporatec/>:<Error/>
  },
//// for resion
{
  path: "/state",
  element:islogin=="true"?<Statec/>:<Error/>
},
{
  path: "/state/add",
  element: islogin=="true"?<Addstatec/>:<Error/>
},


//////////// for product
{
  path: "/product",
  element:islogin=="true"? <Productlistc/>:<Error/>
},
{
  path: "/product/add",
 element:islogin=="true"? <Addproductc/> :<Error/>
},

///////// for component
{
  path: "/component",
 element:islogin=="true"? <Componentc/>:<Error/>
},
{
  path: "/component/add",
 element:islogin=="true"? <Addcomponentc/>:<Error/>
},

///////////////// for unit management

{
  path:'/unit',
 element:islogin=="true"?<Unitc/>:<Error/>
},
{
  path:'/unit/add',
 element:islogin=="true"?<Addunitc/>:<Error/>
}
,
{
  path: "/city",
 element:islogin=="true"? <Cityc/>:<Error/>
},
{
  path: "/city/add",
 element: islogin=="true"?<Addcityc/>:<Error/>
},
{
  path: "/area",
 element:islogin=="true"? <Areac/>:<Error/>
},
{
  path: "/area/add",
 element: islogin=="true"?<Addareac/>:<Error/>
},

////////////////// task

{
  path: "/task",
 element:islogin=="true"? <Taskc/>:<Error/>
},
{
  path: "/task/add",
 element: islogin=="true"?<Addtaskc/>:<Error/>
},

{
  path: "/task/multiTask",
 element: islogin=="true"?<Multitaskc/>:<Error/>
},
///////// for stock management
{
  path: "/stock/add",
 element: islogin=="true"?<Stockc/>:<Error/>
},

{
  path: "/stock",
 element: islogin=="true"?<Stocklistc/>:<Error/>
},

{
  path: "/sheet",
 element: islogin=="true"?<Exelspreadsheet/>:<Error/>
},


])
} />

 </Box>
  );
}

export default App;
