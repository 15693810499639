import { Typography } from '@mui/material'
import React, { Component } from 'react'

export class Error extends Component {
  render() {
    return (
      <div>
        <Typography sx={{fontSize:18,fontWeight:'700',color:'red'}}>
            Page Not Found
        </Typography>
      </div>
    )
  }
}

export default Error