import React, { Component } from 'react'
import { Box, Typography ,Toolbar} from '@mui/material'
import Appheader from '../Projectfile/Appheader'
import {Sidebarc} from '../Projectfile/Sidebar'
import Cheakin from '../Projectfile/Cheakin'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {  Button, Divider, Grid, Paper, TextField,InputAdornment,Radio,TablePagination,Modal,MenuItem,Dialog} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { alpha } from '@mui/material/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate,useLocation,useMatch } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FilterListIcon from '@mui/icons-material/FilterList';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import base_url from '../base'
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



export class Stocklist extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
     page_name:"state",

     order:"asc",
     orderBy:'calories',
     selected:[],
     page:0,
     dense:false,
     rowsPerPage:10,

      
     search:"",
     openmodel:false,
//////////////// for edit
stockArray:[],

assetsArray:[],


assetsjson:{},


openmodelforedit:false,
openmodeforeditsecond:false,
//////////////////////////////////////////////////////////////////
component_name:"",
quentity:0,
user_id:"",
component_id:"",
//////////////////////////////////
open_for_delete:false,
deleted_stock_id:"",


    }
    this.handleChange = this.handleChange.bind()
  }

componentDidMount(){
 
  fetch(`${base_url.base_url}/retrive_stock`, {
    headers:{
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
  'content-type':'application/json',
 // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    },
        method: "post",
        body:JSON.stringify({})

      }).then(function(response) {
        return response.json();
      })
      .then((data)=> {
      this.setState({stockArray:data.data})
      
      });
    

}



handleChange=(e)=>{
  this.setState({[e.target.name]:e.target.value});
}





 





edit=()=>{
  if(this.state.user_id!=="" && this.state.component_id!=="" && this.state.quentity!==""){
    fetch(`${base_url.base_url}/update_quentity`, {
    headers:{
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
  'content-type':'application/json',
 // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    },
        method: "put",
        body:JSON.stringify({
          user_id:this.state.user_id,
          component_id:this.state.component_id,
          quentity:this.state.quentity,
        })

      }).then(function(response) {
        return response.json();
      })
      .then(async(data)=> {
 
      fetch(`${base_url.base_url}/retrive_updated_data`, {
        headers:{
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      'content-type':'application/json',
     // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        },
            method: "post",
            body:JSON.stringify({
              user_id:this.state.user_id,
            })
    
          }).then(function(response) {
            return response.json();
          })
          .then((data)=> {
          this.setState({assetsjson:data.data});
          this.instantUpdate();
          this.setState({openmodeforeditsecond:false});
          this.updatePopup()
          });
      });
  }else{
    this.erorPopup();
  }
  
}



submit=()=>{
 
  
}


instantUpdate=()=>{
  fetch(`${base_url.base_url}/retrive_stock`, {
    headers:{
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
  'content-type':'application/json',
 // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    },
        method: "post",
        body:JSON.stringify({})

      }).then(function(response) {
        return response.json();
      })
      .then((data)=> {
      this.setState({stockArray:data.data});
      this.updatePopup();
      });
}



delete=(da)=>{

  fetch(`${base_url.base_url}/delete_stock`, {
    headers:{
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
  'content-type':'application/json',
 // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    },
        method: "delete",
        body:JSON.stringify({
          id:this.state.deleted_stock_id,
        })

      }).then(function(response) {
        return response.json();
      })
      .then((data)=> {
  this.instantUpdate();
      this.deletePopup();
      this.setState({open_for_delete:false})
      });


}

deletePopup = () => toast.success("Deleted Succesfully")
updatePopup = () => toast.success("Updated Succesfully")
erorPopup =()=> toast.error("All Fields are Compulsory",{
  theme: "colored"
})










handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  this.setState({order:isAsc ? 'desc' : 'asc'});
  this.setState({orderBy:property})
 
};

 handleSelectAllClick = (event) => {
  if (event.target.checked) {
    const newSelected = this.state.productArray.map((n) => n.product_id);
    this.setState({selected:newSelected})
 
    return;
  }
  this.setState({selected:[]})
};

 handleClick = (event, name) => {
  const selectedIndex = this.state.selected.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(this.state.selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(this.state.selected.slice(1));
  } else if (selectedIndex === this.state.selected.length - 1) {
    newSelected = newSelected.concat(this.state.selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      this.state.selected.slice(0, selectedIndex),
      this.state.selected.slice(selectedIndex + 1),
    );
  }
  this.setState({selected:newSelected})
};

handleChangePage = (event, newPage) => {
  this.setState({page:newPage})
};

handleChangeRowsPerPage = (event) => {
  this.setState({rowsPerPage:parseInt(event.target.value, 10)})
  this.setState({page:0})
};
 isSelected = (name) => this.state.selected.indexOf(name) !== -1;



  render() {

  
    const emptyRows =
    this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - this.state.stockArray.length) : 0;
 let mm =   stableSort(this.state.stockArray, getComparator(this.state.order, this.state.orderBy)).slice(
        this.state.page * this.state.rowsPerPage,
        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
      )

let m = mm?mm.filter((e=>(e.user_name=="" || e.user_name.toLowerCase().includes(this.state.search.toLowerCase())))):null





    return (
      <Box sx={{backgroundColor:'#f8f9ff'}}>
      <Appheader/>
      <br/>
    <Box sx={{display:'flex',backgroundColor:'#f8f9ff'}}>
    <Sidebarc/>
    <Box
     component="main"
     sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - 240px)` } }}
    >
      <Box sx={{marginTop:3}}>
<Cheakin data={this.state.page_name}/>
<Paper elevation={1} sx={{minHeight:600,padding:2}}> 
<Box sx={{marginLeft:{xs:'1%',sm:'3%'},marginRight:{xs:'1%',sm:'3%'}}}>

<Box sx={{}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6} md={6}>
   <Box sx={{display:'flex',justifyContent:'center',}}>
   <TextField
size='small'
onChange={this.handleChange}
sx={{backgroundColor:'#f8f9ff',borderRadius:2,padding:0.5,"& input::placeholder": {
    fontSize: "13px"
  }}}
fullWidth
name="search"
        id="input-with-icon-textfield"
        placeholder='Name        '
        InputProps={{
            disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{color:'#a2a2a6'}} />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
   </Box>
  </Grid>
  <Grid item xs={12} sm={6} md={6}>
   <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'right'},flexDirection:'row',}}>
  
<Button variant='contained' onClick={()=>this.props.navigate('/stock/add')} disableElevation startIcon={<AddIcon />} size='small' sx={{textTransform:'none',backgroundColor:'#e26511'}}>
Add Stock
</Button>
   </Box>
  </Grid>
  </Grid>
</Box>


<ToastContainer 
position="top-right"
autoClose={5000}
newestOnTop={false}
closeOnClick
rtl={false}
hideProgressBar
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>


<Box sx={{ overflow: "auto" }}>
<Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
<Box sx={{minHeight:400,marginTop:3}}>

<TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>No</TableCell>
            <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Name</TableCell>
            <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Last Modified Date</TableCell>
            <TableCell align="center" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Status</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {m.map((row,index) => (
            <TableRow
              key={row.name}
              sx={{border:0}}
            >
              <TableCell align="left" sx={{fontSize:13,}}> {this.state.page * this.state.rowsPerPage +  index + 1}</TableCell>
              <TableCell align="left" sx={{fontSize:13,}}>{row.user_name}</TableCell>
              <TableCell align="left" sx={{fontSize:13,}}>{moment(row.last_update_date).format('DD MMM YYYY hh:mm:ss')}</TableCell>
              <TableCell align="center"><BorderColorIcon sx={{color:'green',height:16,width:16}} onClick={()=>this.setState({assetsjson:row,openmodelforedit:true})} />  <DeleteIcon sx={{color:'red',height:16,width:16}} onClick={()=>this.setState({deleted_stock_id:row.user_id},()=>{this.setState({open_for_delete:true})})}/> <RemoveRedEyeIcon sx={{color:'#3f4290',height:16,width:16}} onClick={()=>this.setState({assetsArray:row.assets,openmodel:true})}/>  </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[5, 10,20, 25]}
          component="div"
          count={this.state.stockArray.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

</Box>
</Box>
</Box>
</Box>
</Paper>
  </Box>
    </Box>
    </Box>

    <Box sx={{width:'100%'}}>
<Modal
style={{display:'flex',justifyContent:'center',alignItems:'center',borderColor:'white'}}
  open={this.state.openmodel}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Paper elevation={1} sx={{maxHeight:{xs:'90vh',sm:'80vh'},width:{xs:'95%',sm:'80%'},overflowY:'scroll'}}>
    <Paper onClick={()=>this.setState({openmodel:false})} elevation={5} sx={{height:30,width:30,backgroundColor:'#e5e2fc',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <CloseIcon color='#1c446a' />
    </Paper>

  <Box sx={{marginTop:5}}>
  <Typography sx={{display:'flex',color:'#c1c1c1',marginTop:5,fontSize:15,fontWeight:'600',marginLeft:{xs:'4%',sm:'15%'},marginRight:{xs:'4%',sm:'15%'}}} >Items Given (Quantity)<Typography sx={{color:'red',fontSize:15}}>*</Typography> </Typography>
<Box sx={{display:'flex',justifyContent:'center',marginTop:0.5,marginLeft:{xs:'4%',sm:'15%'},marginRight:{xs:'4%',sm:'15%'}}}>
 

<TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>No</TableCell>
            <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Component Name</TableCell>
            <TableCell align="right" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Quantity</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.assetsArray.map((row,index) => (
            <TableRow
              key={row.name}
              sx={{border:0}}
            >
              <TableCell align="left" sx={{fontSize:13,}}>{index + 1}</TableCell>
              <TableCell align="left" sx={{fontSize:13,}}>{row.component_name}</TableCell>
              <TableCell align="right" sx={{fontSize:13,}}>{row.quentity}</TableCell>
             
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>






</Box>
</Box>
<br/>
<br/>
</Paper>

</Modal>
</Box>







<Box sx={{width:'100%'}}>
<Modal
style={{display:'flex',justifyContent:'center',alignItems:'center',borderColor:'white'}}
  open={this.state.openmodeforeditsecond}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Paper elevation={1} sx={{maxHeight:{xs:'90vh',sm:'80vh'},width:{xs:'80%',sm:'80%'},overflowY:'scroll'}}>
    <Paper onClick={()=>this.setState({openmodeforeditsecond:false})} elevation={5} sx={{height:30,width:30,backgroundColor:'#e5e2fc',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <CloseIcon color='#1c446a' />
    </Paper>

  <Box sx={{marginTop:5}}>
<Box sx={{display:'flex',justifyContent:'center',marginTop:0.5,marginLeft:{xs:'4%',sm:'15%'},marginRight:{xs:'4%',sm:'15%'},flexDirection:'column'}}>
 
<Grid container item spacing={2}>
<Grid item sm={6} xs={12}>
<TextField placeholder='Component Name' name="component_name" value={this.state.component_name} fullWidth size='small' />
</Grid>

<Grid item sm={6} xs={12}>
<TextField placeholder='Quantity' name='quentity' type='number' value={this.state.quentity} fullWidth size='small' onChange={this.handleChange}/>
</Grid>

</Grid>


<br/>
<Box sx={{display:'flex',justifyContent:'right',width:'100%'}}>
  <Button variant='contained' size='small' onClick={this.edit} sx={{width:100,display:'flex',alignContent:'right',backgroundColor:'#05507a',textTransform:'none'}}>
  Update
</Button>
</Box>


 </Box>
 </Box>
 <br/>
 <br/>
</Paper>
</Modal>
</Box>











<Box sx={{width:'100%'}}>
<Modal
style={{display:'flex',justifyContent:'center',alignItems:'center',borderColor:'white'}}
  open={this.state.openmodelforedit}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Paper elevation={1} sx={{maxHeight:{xs:'90vh',sm:'80vh'},width:{xs:'95%',sm:'80%'},overflowY:'scroll',display:this.state.openmodeforeditsecond?'none':'block'}}>
    <Paper onClick={()=>this.setState({openmodelforedit:false})} elevation={5} sx={{height:30,width:30,backgroundColor:'#e5e2fc',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <CloseIcon color='#1c446a' />
    </Paper>

  <Box sx={{marginTop:5}}>
  <Typography sx={{display:'flex',color:'#c1c1c1',marginTop:5,fontSize:15,fontWeight:'600',marginLeft:{xs:'4%',sm:'15%'},marginRight:{xs:'4%',sm:'15%'}}} >Items Given (Quantity)<Typography sx={{color:'red',fontSize:15}}>*</Typography> </Typography>
<Box sx={{display:'flex',justifyContent:'center',marginTop:0.5,marginLeft:{xs:'4%',sm:'15%'},marginRight:{xs:'4%',sm:'15%'}}}>
 

<TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>No</TableCell>
            <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Component Name</TableCell>
            <TableCell align="center" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Quantity</TableCell>
            <TableCell align="right" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Action</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.assetsjson.assets?this.state.assetsjson.assets.map((row,index) => (
            <TableRow
              key={row.name}
              sx={{border:0}}
            >
              <TableCell align="left" sx={{fontSize:13,}}>{index + 1}</TableCell>
              <TableCell align="left" sx={{fontSize:13,}}>{row.component_name}</TableCell>
              <TableCell align="center" sx={{fontSize:13,}}>{row.quentity}</TableCell>
              <TableCell align="right" sx={{fontSize:13}}><BorderColorIcon sx={{color:'green',height:16,width:16}} onClick={()=>this.setState({component_name:row.component_name,quentity:row.quentity,user_id:this.state.assetsjson.user_id ,component_id:row.component_id,openmodeforeditsecond:true})}/></TableCell>
            </TableRow>
          )):null}
        </TableBody>
      </Table>
    </TableContainer>

</Box>
</Box>
<br/>
<br/>
</Paper>

</Modal>
</Box>




<Dialog
  open={this.state.open_for_delete}
  onClose={this.handleClose}
sx={{width:'100%'}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',minHeight:150,width:250}}>
 
  <DeleteForeverIcon sx={{height:50,width:50,color:'#1c446a'}}/>

<Typography sx={{fontSize:16,fontWeight:'bold',textAlign:'center',color:'#1c446a'}}>Are You sure ?</Typography>


</Box>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'right',padding:1}}>
<Button variant='contained' fullWidth disableElevation size='small' onClick={()=>this.setState({open_for_delete:false})} sx={{marginRight:3,textTransform:'none',backgroundColor:'#05507a'}}>Cancel</Button>
<Button variant='contained'fullWidth disableElevation size='small' onClick={this.delete} sx={{textTransform:'none',backgroundColor:'#05507a'}}>Ok</Button>
</Box>
</Dialog>


    </Box>
    )
  }
}

export default Stocklist
export function Stocklistc(props){
    const navigate = useNavigate();
    const location = useLocation();
    return (<Stocklist location={location} navigate={navigate}></Stocklist>)
  }
  