import React, { Component } from 'react'
import {Box, Card, Paper, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Inventory2Icon from '@mui/icons-material/Inventory2';

import { useNavigate,useLocation,useMatch,Link } from 'react-router-dom';

export class Sidebar extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       data:"",
       textcolor:"#33339c",
       bgcolor:"",

       id:1,
       pathname:this.props.location.pathname,

       openResion:false,
    }
  }
 
first=()=>{
  this.setState({id:1},()=>{
     this.props.navigate('/dashboardc');
  });
 
}

second = ()=>{
  this.setState({id:2},()=>{
    this.props.navigate('/users');
  });
  
}

third=()=>{
  this.setState({id:3},()=>{
    this.props.navigate('/activities')
  })
  
}



componentDidMount(){
  if(this.state.pathname=="/state"  || this.state.pathname=="/state/add"){
    this.setState({openResion:true})
  }

  if(this.state.pathname=="/area" || this.state.pathname=="/area/add"){
    this.setState({openResion:true})
  }


  if(this.state.pathname=="/city" || this.state.pathname=="/city/add"){
    this.setState({openResion:true})
  }


}



  render() {
   
    return (
      <div>
        <Box sx={{display:{xs:'none',sm:'flex'},marginTop:7,bottom:0,minWidth:240,height:'100vh',position:'sticky'}}>
        <Paper sx={{width:240,backgroundColor:'white',margin:1,borderRadius:2,position:'fixed',height:'90vh',overflowY:'scroll', scrollbarWidth:'none','&::-webkit-scrollbar': {
        display: 'none',
    }, }}  elevation={1}>


<Box  sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=="/dashboardc"?'#ebebf5':"white",display:'flex',justifyContent:'left',marginTop:2,borderRadius:2,alignItems:'center'}} onClick={this.first}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <DashboardIcon  sx={{marginLeft:2,color:this.state.pathname=="/dashboardc"?'#33339c':"#8e8e93"}}/>
      <Box  sx={{width:'100%',marginLeft:'25%'}}>
       <Link style={{textDecoration:'none'}}>
         <Typography  sx={{textDecoration:'none',textAlign:'left',fontSize:15,fontWeight:'600',color:this.state.pathname=="/dashboardc"?'#33339c':"#212121",marginLeft:-4}}>Dashboard</Typography>
        </Link>
      </Box>
  </Box>
</Box>


<Box  sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/users'?'#ebebf5':"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={this.second}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <GroupIcon  sx={{marginLeft:2,color:this.state.pathname=='/users'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textDecoration:'none',textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/users'?'#33339c':"#212121",marginLeft:-4}}>Users</Typography>
         </Link>
      </Box>
  </Box>
</Box>


<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/activities'?'#ebebf5':this.state.pathname=='/activities/add'?'#ebebf5':'white',display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={this.third}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <ContactPageIcon  sx={{marginLeft:2,color:this.state.pathname=='/activities'?'#33339c':this.state.pathname=='/activities/add'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>

      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/activities'?'#33339c':this.state.pathname=='/activities/add'?'#33339c':"#212121",marginLeft:-4}}>Activities</Typography>
         </Link>
      </Box>
  </Box>
</Box>


<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/task'?'#ebebf5':this.state.pathname=='/task/add'?'#ebebf5':'white',display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>this.props.navigate('/task')}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <TaskAltIcon  sx={{marginLeft:2,color:this.state.pathname=='/task'?'#33339c':this.state.pathname=='/task/add'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/task'?'#33339c':this.state.pathname=='/task/add'?'#33339c':"#212121",marginLeft:-4}}>Task</Typography>
         </Link>
      </Box>
  </Box>
</Box>



<Box sx={{marginLeft:2,marginRight:2,height:40,   backgroundColor:this.state.pathname=='/component'?'#ebebf5':this.state.pathname=='/component/add'?'#ebebf5':'white' ,display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>this.props.navigate('/component')}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <CleanHandsIcon  sx={{marginLeft:2,color:this.state.pathname=='/component'?'#33339c':this.state.pathname=='/component/add'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/component'?'#33339c':this.state.pathname=='/component/add'?'#33339c':"#212121",marginLeft:-4}}>Component</Typography>
         </Link>
      </Box>
  </Box>
</Box>



<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/product'?'#ebebf5':this.state.pathname=='/product/add'?'#ebebf5':'white' ,display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>this.props.navigate('/product')}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <ViewInArIcon  sx={{marginLeft:2,color:this.state.pathname=='/product'?'#33339c':this.state.pathname=='/product/add'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/product'?'#33339c':this.state.pathname=='/product/add'?'#33339c':"#212121",marginLeft:-4}}>Products</Typography>
         </Link>
      </Box>
  </Box>
</Box>


<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/corporate'?'#ebebf5':"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>this.props.navigate("/corporate")}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <CorporateFareIcon  sx={{marginLeft:2,color:this.state.pathname=='/corporate'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/corporate'?'#33339c':"#212121",marginLeft:-4}}>Corporates</Typography>
         </Link>
      </Box>
  </Box>
</Box>


<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.openResion==true?'#ebebf5':"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>{this.state.openResion? this.setState({openResion:false}) : this.setState({openResion:true}) }}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
      <PersonPinIcon  sx={{marginLeft:2,color:this.state.openResion==true?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.openResion==true?'#33339c':"#212121",marginLeft:-4}}>Region</Typography>
         </Link>
      </Box>
      {
        this.state.openResion===true?<ArrowDropUpIcon  sx={{marginRight:2,color:this.state.openResion==true?'#33339c':"#8e8e93"}}  onClick={()=>{alert("po")}} />:<ArrowDropDownIcon  sx={{marginRight:2,color:this.state.id==7?'#33339c':"#8e8e93"}} onClick={()=>this.setState({openResion:false})}/>
      }
      
  </Box>
</Box>



{
  this.state.openResion==true?
  <Box>
<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={async()=>{ this.props.navigate('/state');this.setState({openResion:true})}}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
    <ContactPageIcon  sx={{color:'white',marginLeft:2}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.id==7?'#33339c':"#212121",marginLeft:-4}}>State</Typography>
         </Link>
      </Box>
  </Box>
</Box>


<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={async()=>{ this.props.navigate('/city');this.setState({openResion:true})}}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
    <ContactPageIcon  sx={{color:'white',marginLeft:2}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.id==7?'#33339c':"#212121",marginLeft:-4}}>City</Typography>
         </Link>
      </Box>
     
  </Box>
</Box>



<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}}  onClick={async()=>{ this.props.navigate('/area');this.setState({openResion:true})}}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
    <ContactPageIcon  sx={{color:'white',marginLeft:2}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.id==7?'#33339c':"#212121",marginLeft:-4}}>Area</Typography>
         </Link>
      </Box>
     
  </Box>
</Box>





  </Box>:null

}












<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/unit'?'#ebebf5':"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>this.props.navigate('/unit')}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <ManageAccountsIcon  sx={{marginLeft:2,color:this.state.pathname=='/unit'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'600',fontSize:15,color:this.state.pathname=='/unit'?'#33339c':"#212121",marginLeft:-4}}>Unit Management</Typography>
         </Link>
      </Box>
  </Box> 
</Box>


<Box sx={{marginLeft:2,marginRight:2,height:40,backgroundColor:this.state.pathname=='/stock'?'#ebebf5':"white",display:'flex',justifyContent:'left',marginTop:1,borderRadius:2,alignItems:'center'}} onClick={()=>this.props.navigate('/stock')}>
    <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
      <Inventory2Icon  sx={{marginLeft:2,color:this.state.pathname=='/stock'?'#33339c':"#8e8e93"}}/>
      <Box sx={{width:'100%',marginLeft:'25%'}}>
      <Link style={{textDecoration:'none'}}>
         <Typography sx={{textAlign:'left',fontWeight:'550',fontSize:15,color:this.state.pathname=='/stock'?'#33339c':"#212121",marginLeft:-4}}>Stock Management</Typography>
         </Link>
      </Box>
  </Box>
</Box>

<br/>
<br/>
<br/>
        </Paper>
        </Box>
        
        </div>
    )
  }
}

export default Sidebar

export function Sidebarc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Sidebar location={location} navigate={navigate}></Sidebar>)
}
