import React, { Component } from 'react'
import  { Paper ,Box, Grid, Typography,TextField,MenuItem} from '@mui/material'
import Chart from 'react-apexcharts'
import base_url from './base';
import moment from 'moment';
export class Chartstwo extends Component {
  constructor(props) {
    super(props);
   // [this.props.to_do.length,this.props.on_going.length,this.props.complete.length,this.props.cancel.length],
    this.state = {
      
value:"",
taskArray:[],
productArray:[],
userArray:[],



selected_date:"",
product_name:"",
user_name:"",


      seriess:[],
      optionss: {
        labels: ["To do", "On going", "Completed", "Canceled"],
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width:'100%'
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },




   
      categories: [moment().format('DD-MM-YYYY'),moment().subtract(1, "days").format('DD-MM-YYYY'),moment().subtract(2, "days").format('DD-MM-YYYY'),moment().subtract(3, "days").format('DD-MM-YYYY'),moment().subtract(4, "days").format('DD-MM-YYYY')],
      series: [],

    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange=(e)=>{
    this.setState({[e.target.name]:e.target.value},()=>{
      if(this.state.selected_date!==""){
        this.setState({
          categories : [moment(this.state.selected_date).format('YYYY-MM-DD')]
        },()=>{
           this.second_graph_function()
        })
      }else{
        this.second_graph_function()
      }
     
    })
  }

componentDidMount(){
  fetch(`${base_url.base_url}/retriveTask`, {
    headers:{
  'authorization': `Bearer ${sessionStorage.getItem('token')}`,
    'content-type':'application/json'
    },
        method: "post",
        body:JSON.stringify({})
      }).then((response) => { 
        if(response.status==200){
           response.json().then(async(data)=> {
            this.setState({taskArray:data.data},async()=>{
              fetch(`${base_url.base_url}/retriveProduct`, {
                headers:{
                  'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type':'application/json'
                },
                    method: "post",
                    body:JSON.stringify({})
                  }).then((response) => { 
                    if(response.status==200){
                       response.json().then((data)=> {
              this.setState({productArray:data.data})
                      });
                   } }).then(async()=>{
                   await this.second_graph_function();
                  
                   })


            });


          })
      .then(async()=>{
       
    
        

       })
      } })


     fetch(`${base_url.base_url}/retriveUsers`, {
      headers:{
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      'content-type':'application/json'
      },
          method: "post",
          body:JSON.stringify({})
        }).then((response) => { 
          if(response.status==200){
             response.json().then((data)=> {
    this.setState({userArray:data.data})
            });
         } })


}








second_graph_function = async()=>{
  let productArray = this.state.productArray
let task_array = this.state.taskArray;
let date_arrayy  =[];
for (let i = 0; i < 5; i++) {
  var ll  = moment().subtract(i, "days").format('YYYY-MM-DD')
date_arrayy.push(ll)
}

let filter_taskk =  task_array.filter((e)=>(e.product_name=="" || e.product_name.includes(this.state.product_name)) && (e.task_assigned_user_name=="" || e.task_assigned_user_name.includes(this.state.user_name)) &&  (e.task_status=="" || e.task_status.includes('Complete'))  && ( this.state.selected_date=="" ? e.task_status.includes('Complete') :  moment(e.task_end_date).format('YYYY-MM-DD') == moment(this.state.selected_date).format('YYYY-MM-DD')))    /// (e.task_end_date==null? e.task_end_date==e.task_end_date : moment(e.task_end_date).format('DD-MM-YYYY') == this.state. ))
console.log(filter_taskk )
let series = [];


for (let i = 0; i < date_arrayy.length; i++) {
  let filter_task =  filter_taskk.filter((e =>(  moment(e.task_end_date).format('YYYY-MM-DD') == moment(date_arrayy[i]).format('YYYY-MM-DD')   )))
  series.push(filter_task.length)

}


this.setState({
 series : [{
    name: 'Completed Task',
    data: series
  }]
})

console.log(series)





}








  render() {


let task_com = this.state.taskArray.filter((e)=>( e.product_name=="" || e.product_name.includes(this.state.value) ))
    let comlete_task = task_com.filter((e)=>(e.task_status=="" || e.task_status.includes('Complete')))
    let cancel_task = task_com.filter((e)=>(e.task_status=="" || e.task_status.includes('Cancel')))
    let on_going_task = task_com.filter((e)=>(e.task_status=="" || e.task_status.includes('On Going')))
    let to_do_task = task_com.filter((e)=>(e.task_status=="" || e.task_status.includes('To do')))


//////////////////  for second graph

let  options = {
  chart: {
    id: 'apexchart-example'
  },
  xaxis: {
    categories: this.state.categories
  }
}







    return (
      <div>
<Box sx={{marginTop:2}}>
<Box  sx={{minHeight:320,width:'100%',marginTop:2}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
    <Paper>
  <Box sx={{height:'100%',width:'100%'}}>
  <Box sx={{padding:2}}>
<TextField
select
placeholder='Select Component'
label='Select Type'
size='small'
sx={{width:250}}
onChange={this.handleChange}
name='value'
value={this.state.value}
valu
>   <MenuItem key='1' value=""  onClick={()=>this.setState({value:""})}>
     Select
    </MenuItem>
  {
    this.state.productArray.map((d)=>(
       <MenuItem key={d.productname} value={d.productname} onClick={()=>{this.setState({value:d.productname})}}>
        {d.productname}
       </MenuItem>
    ))
  }
</TextField>

        </Box>


  <Chart options={this.state.optionss} series={[to_do_task.length,on_going_task.length,comlete_task.length,cancel_task.length]} type="donut" width='100%' height={320} />

  </Box>
  </Paper>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Paper>
  <Box sx={{height:'100%',width:'100%'}}>

<Box sx={{display:'flex',flexDirection:{xs:'column',sm:'row'},padding:2,justifyContent:'space-between'}}>
<TextField
select
type='text'
size='small'
placeholder='Type'
label='Service Type'
sx={{width:{xs:'100%',sm:200,padding:2}}}
onChange={this.handleChange}
name='product_name'
value={this.state.product_name}
>
<MenuItem key='1' value=""  onClick={()=>this.setState({product_name:""})}>
     Select
    </MenuItem>
  {
    this.state.productArray.map((d)=>(
       <MenuItem key={d.productname} value={d.productname} onClick={()=>{this.setState({product_name:d.productname},()=>{this.second_graph_function()})}}>
        {d.productname}
       </MenuItem>
    ))
  }
</TextField>

<TextField
select
type='text'
size='small'
label='Assigned User'
sx={{width:{xs:'100%',sm:200,padding:2}}}
onChange={this.handleChange}
name='user_name'
value={this.state.user_name}
>
<MenuItem key='1' value=""  onClick={()=>this.setState({user_name:""})}>
     Select
    </MenuItem>
  {
    this.state.userArray.map((d)=>(
       <MenuItem key={d.username} value={d.username} onClick={()=>{this.setState({user_name:d.username},()=>{this.second_graph_function()})}}>
        {d.username}
       </MenuItem>
    ))
  }
</TextField>

</Box>


  <Chart options={options} series={this.state.series} type="area" width='100%' height={320} />

</Box>
</Paper>
</Grid>
</Grid>
</Box>
</Box>
      </div>
    )
  }
}

export default Chartstwo