import React, { Component, useState } from 'react'
import { Box, Typography ,Toolbar, Alert, Stack, Backdrop, CircularProgress, touchRippleClasses, Dialog} from '@mui/material'
import Appheader from '../Projectfile/Appheader'
import {Sidebarc} from '../Projectfile/Sidebar'
import Cheakin from '../Projectfile/Cheakin'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {  Button, Divider, Grid, Paper, TextField,InputAdornment,Radio,TablePagination,Modal,MenuItem,TextareaAutosize} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { alpha } from '@mui/material/styles';

import { useNavigate,useLocation,useMatch } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FilterListIcon from '@mui/icons-material/FilterList';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { withAlert } from 'react-alert'
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DownloadIcon from '@mui/icons-material/Download';
import base_url from '../base';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CSVLink } from "react-csv";

//////////    rowCount > 0 && numSelected === rowCount
let array= [];



const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'No',
        numeric: false,
        disablePadding: true,
        label: 'No',
      },
        {
          id: 'Title',
          numeric: false,
          disablePadding: false,
          label: 'Title',
        },
      {
        id: 'Statrdate',
        numeric: false,
        disablePadding: false,
        label: 'Start Date',
      },
      {
        id: 'enddate',
        numeric: false,
        disablePadding: false,
        label: 'End Date',
      },
      {
        id: 'Area',
        numeric: false,
        disablePadding: false,
        label: 'Area',
      },
      {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
      },
      {
        id: 'Assigned_to',
        numeric: false,
        disablePadding: false,
        label: 'Assigned_To',
      },
      {
        id: 'Status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
      },
      {
        id: 'Action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
      },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected > 0}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
             sx={{fontSize:15,fontWeight:'500'}}
            >
              {headCell.label}
             
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected ,selected,call} = props;
const[open,Setopen] = useState(false)
  function deleteAll(){
  
      fetch(`${base_url.base_url}/bulkdeleteTask`, {
  
      headers:{
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      'content-type':'application/json'
      },
          method: "delete",
          body:JSON.stringify({
            data:selected
            })
      
        }).then( (response) => { 
          if(response.status==200){
             response.json().then((data)=> {
      call();
      Setopen(false);
      window.location.reload();
            });
         } })
  
      
  }


  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={()=>{
Setopen(true)


          }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}








<Dialog
  open={open}
  //onClose={this.handleClose}
sx={{width:'100%'}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',minHeight:150,width:250}}>
 
  <DeleteForeverIcon sx={{height:50,width:50,color:'#1c446a'}}/>

<Typography sx={{fontSize:16,fontWeight:'bold',textAlign:'center',color:'#1c446a'}}>Are You sure ?</Typography>

</Box>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'right',padding:1}}>
<Button variant='contained' fullWidth disableElevation size='small' onClick={()=>Setopen(false)} sx={{marginRight:3,textTransform:'none',backgroundColor:'#05507a'}}>Cancel</Button>
<Button variant='contained'fullWidth disableElevation size='small' onClick={()=>deleteAll()} sx={{textTransform:'none',backgroundColor:'#05507a'}}>Ok</Button>
</Box>
</Dialog>




    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export class Task extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
backdrop_open:false,


       page:"User",
       data:[{id:1,name:"dkfj"},{id:2,name:"dkfj"}],

       order:"asc",
       orderBy:'calories',
       selected:[],
       page:0,
       dense:false,
       rowsPerPage:10,
search:"",
////////////////////////////////
taskArray:[],
/////////////////////// for model 
openmodel:false,
////////////////////////////////////// for edit section
productArray:[],
unitArray:[],
corporateArray:[],
userArray:[],
areaArray:[],   /// newwly added
///////////////////// /////////////
task_title:"",
is_installation:false,
is_maintainance:false,

product_name:"",
product_id:"",

unit_name:"",
unit_id:"",
state_name:"",
city_name:"",
area_name:'',
address:'',

corporate_name:"",
corporate_id:"",

execution_date:null,


callaboration_user_name:"",
callaboration_user_id:"",

description:"",

user_name:"",
user_id:"",

status:"",
execution_time:null,

task_id:"",

latitude:"",
longitude:"",



    cancel_date:null,     /// gchanges
    on_going_date:null,  /// changes
    task_end_date:null,



///////////////////////////////////////////////////
// for filter purpus
start_date : new Date('July 20, 1970 00:20:18'),
end_date : new Date('July 20, 2050 00:20:18'),



start_date_c : new Date(),
end_date_c : new Date(),

opencalender:false,
/////////////////////////////////////  filter
service_type_a_maintainance :"",
area_working:"",
assigne_to_user:"",

name_maintain_b:""

    }
    this.handleChange=this.handleChange.bind(this)
}

  

  handleChange=(e)=>{
    this.setState({[e.target.name]:e.target.value})
  }
  

componentDidMount(){
  fetch(`${base_url.base_url}/retriveTask`, {
                    headers:{
                      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'content-type':'application/json'
                    },
                        method: "post",
                        body:JSON.stringify({})
                      }).then((response) => { 
                        if(response.status==200){
                           response.json().then((data)=> {
                 this.setState({taskArray:data.data.sort((a,b)=>parseInt(b.index)-parseInt(a.index))})
                          });
                       } })

                       fetch(`${base_url.base_url}/retriveProduct`, {
                        headers:{
                          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        'content-type':'application/json'
                        },
                            method: "post",
                            body:JSON.stringify({})
                          }).then((response) => { 
                            if(response.status==200){
                               response.json().then((data)=> {
                      this.setState({productArray:data.data})
                              });
                           } })
                
                
                           fetch(`${base_url.base_url}/retriveUnit`, {
                           headers:{
                            'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                           'content-type':'application/json'
                           },
                               method: "post",
                               body:JSON.stringify({
                         state_id:this.state.state_id,
                         city_name:this.state.city_name,
                         state:this.state.state,
                               })
                             }).then((response) => { 
                               if(response.status==200){
                                  response.json().then((data)=> {
                         this.setState({unitArray:data.data})
                                 });
                              } })
                
                              fetch(`${base_url.base_url}/retriveCorporate`, {
                                headers:{
                                  'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                                'content-type':'application/json'
                                },
                                    method: "post",
                                    body:JSON.stringify({})
                                  }).then((response) => { 
                                    if(response.status==200){
                                       response.json().then((data)=> {
                              this.setState({corporateArray:data.data})
                                      });
                                   } })
                
                
                                   fetch(`${base_url.base_url}/retriveUsers`, {
                                    headers:{
                                      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                                    'content-type':'application/json'
                                    },
                                        method: "post",
                                        body:JSON.stringify({})
                                      }).then((response) => { 
                                        if(response.status==200){
                                           response.json().then((data)=> {
                                  this.setState({userArray:data.data})
                                          });
                                       } })



                                       fetch(`${base_url.base_url}/Retrivearea`, {
                                        headers:{
                                          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                                        'content-type':'application/json'
                                        },
                                            method: "post",
                                            body:JSON.stringify({})
                                          }).then((response) => { 
                                            if(response.status==200){
                                               response.json().then((data)=> {
                                      this.setState({areaArray:data.data})
                                              });
                                           } })
}


  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({order:isAsc ? 'desc' : 'asc'});
    this.setState({orderBy:property})
   
  };
  
   handleSelectAllClick = (event) => {
    if (event.target.checked) { 
      const newSelected = array.map((n) => n.task_id);
      this.setState({selected:newSelected})
      return;
    }
          this.setState({selected:[]})
    

  };
  
   handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({selected:newSelected})
  };
  


  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  //  console.log(newPage)

 



  };
  
  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:parseInt(event.target.value, 10)})
    this.setState({page:0})

  };



   isSelected = (name) => this.state.selected.indexOf(name) !== -1;


edit=(data)=>{
this.setState({

  cancel_date:data.cancel_date,     /// gchanges
          on_going_date:data.on_going_date,  /// changes
          task_end_date:data.task_end_date,



 openmodel:true,

 task_title:data.task_title,
 is_installation:data.is_installation,
 is_maintainance:data.is_maintainance,
 product_name:data.product_name,
 product_id:data.product_id,
 unit_name:data.unit_name,
 unit_id:data.unit_id,
 state_name:data.task_state,
 city_name:data.task_city,
 area_name:data.task_area,
 address:data.task_address,
 corporate_name:data.corporate_name,
 corporate_id:data.corporate_id,
 execution_date:data.task_execution_date,
 callaboration_user_name:data.calloboration_user_name,
 callaboration_user_id:data.calloboration_user_id,
 description:data.task_description,
 user_name:data.task_assigned_user_name,
 user_id:data.task_assigned_user_id,
 status:data.task_status,
 execution_time:null,
task_id:data.task_id,
latitude:data.latitude,
longitude:data.longitude,
},()=>{
 
})
}



submit=()=>{
  if(this.state.task_title!==""  && this.state.task_id!==""  &&this.state.description!=="" && this.state.user_name!=="" && this.state.user_id!=="" && this.state.product_name!=="" && this.state.product_id!=="" && this.state.execution_date!==null){

    fetch(`${base_url.base_url}/updateTask`, {
      headers:{
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      'content-type':'application/json'
      },
          method: "put",
          body:JSON.stringify({
            cancel_date:this.state.cancel_date,     /// gchanges
            on_going_date:this.state.on_going_date,  /// changes
            task_end_date:this.state.task_end_date,
  
            task_title:this.state.task_title,
            is_installation:this.state.is_installation,
            is_maintainance:this.state.is_maintainance,
            product_name:this.state.product_name,
            product_id:this.state.product_id,
            unit_name:this.state.unit_name,
            unit_id:this.state.unit_id,
            state_name:this.state.state_name,
            city_name:this.state.city_name,
            area_name:this.state.area_name,
            address:this.state.address,
            corporate_name:this.state.corporate_name,
            corporate_id:this.state.corporate_id,
            execution_date:this.state.execution_date,
            callaboration_user_name:this.state.callaboration_user_name,
            callaboration_user_id:this.state.callaboration_user_id,
            description:this.state.description,
            user_name:this.state.user_name,
            user_id:this.state.user_id,
            status:this.state.status,
            execution_time:this.state.execution_time,
            task_id:this.state.task_id,
            latitude:this.state.latitude,
            longitude:this.state.longitude,
          })
        }).then((response) => { 
          if(response.status==200){
             response.json().then((data)=> {
              this.instantUpdate()
           this.updatePopup()
           this.setState({
  
            open_for_delete:false,
            deleted_task_id:"",




            cancel_date:null,     /// gchanges
            on_going_date:null,  /// changes
            task_end_date:null,
  
            task_title:"",
            is_installation:false,
            is_maintainance:false,
            product_name:"",
            product_id:"",
            unit_name:"",
            unit_id:"",
            state_name:"",
            city_name:"",
            area_name:"",
            address:"",
            corporate_name:"",
            corporate_id:"",
            execution_date:null,
            callaboration_user_name:"",
            callaboration_user_id:"",
            description:"",
            user_name:"",
            user_id:"",
            status:"",
            execution_time:null,
            task_id:"",
            latitude:"",
            longitude:"",
            openmodel:false
           })
            });
         } })
       
  }else{
    this.erorPopup()
  }}

  

instantUpdate=()=>{

  fetch(`${base_url.base_url}/retriveTask`, {
                    headers:{
                      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'content-type':'application/json'
                    },
                        method: "post",
                        body:JSON.stringify({})
                      }).then((response) => { 
                        if(response.status==200){
                           response.json().then((data)=> {
                            this.setState({taskArray:data.data.sort((a,b)=>parseInt(b.index)-parseInt(a.index))})
                          });
                      } })

}


delete=()=>{
  fetch(`${base_url.base_url}/deleteTask`, {
  
  headers:{
    'authorization': `Bearer ${sessionStorage.getItem('token')}`,
'content-type':'application/json',
// 'Access-Control-Allow-Origin': 'http://localhost:3000',
  },
      method: "delete",
      body:JSON.stringify({
        task_id:this.state.deleted_task_id
        })

    }).then(function(response) {
      return response.json();
    })
    .then((data)=> {
      this.deletePopup()
      this.instantUpdate()
      this.setState({open_for_delete:false})
    });

}



deletePopup = () => toast.success("Deleted Succesfully")

updatePopup = () => toast.success("Updated Succesfully")

erorPopup =()=> toast.error("All Fields are Compulsory",{
  theme: "colored"
})

servicetype=(data)=>{
  if(data.name==="Maintenance"){
      this.setState({is_maintainance:true,is_installation:false})
  }else{
      this.setState({is_maintainance:false,is_installation:true})
  }
  }












  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({selected:newSelected})
  };



  handleSelect=(e)=>{
    this.setState({start_date_c:e.selection.startDate,end_date_c:e.selection.endDate})
  }

apply=()=>{
  this.setState({start_date:this.state.start_date_c,end_date:this.state.end_date_c,opencalender:false})
}



  render() {

    const selectionRange = {
      startDate: this.state.start_date_c,
      endDate: this.state.end_date_c,
      key: 'selection',
    }

//    let mmc = this.state.taskArray.sort((a,b)=>b.index-a.index);

  

    let main_arr = this.state.taskArray?this.state.taskArray.filter((e=>(e.task_title=="" || e.task_title.toLowerCase().includes(this.state.search.toLowerCase())) && (this.state.service_type_a_maintainance!==""?e.is_maintainance==this.state.service_type_a_maintainance : e.task_id.includes(e.task_id)  ) && (e.task_assigned_user_name=="" || e.task_assigned_user_name.includes(this.state.assigne_to_user)) && (e.task_area=="" || e.task_area.includes(this.state.area_working)) && ( moment(e.task_execution_date).valueOf() >  moment(this.state.start_date).valueOf() && moment(e.task_execution_date).valueOf() <  moment(this.state.end_date).valueOf() )    )):[];

    const alert = this.props.alert;
    const emptyRows =
    this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - main_arr.length) : 0;
 let m =   stableSort(main_arr, getComparator(this.state.order, this.state.orderBy)).slice(
        this.state.page * this.state.rowsPerPage,
        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
      )

//let mc = mm?mm.filter((e=>(e.task_title=="" || e.task_title.toLowerCase().includes(this.state.search.toLowerCase())) && (this.state.service_type_a_maintainance!==""?e.is_maintainance==this.state.service_type_a_maintainance : e.task_id.includes(e.task_id)  ) && (e.task_assigned_user_name=="" || e.task_assigned_user_name.includes(this.state.assigne_to_user)) && (e.task_area=="" || e.task_area.includes(this.state.area_working)) && ( moment(e.task_execution_date).valueOf() >  moment(this.state.start_date).valueOf() && moment(e.task_execution_date).valueOf() <  moment(this.state.end_date).valueOf() )    )):[];

array=main_arr;

let product_array = this.state.productArray?this.state.productArray.filter((e=>(e.is_maintainance==this.state.is_maintainance && e.is_installation==this.state.is_installation ))):[]

let unit_array= this.state.unitArray?this.state.unitArray.filter((e=>(e.corporate_id===this.state.corporate_id))):[]
   
let user_array=this.state.userArray.filter((e=>(e.is_installation===this.state.is_installation || e.is_maintainance===this.state.is_maintainance)));

let calloboration_array = user_array.filter((e=>(e.user_id!==this.state.user_id)));


    return (
      <Box sx={{backgroundColor:'#f8f9ff'}}>
  <Appheader/>
  <br/>
<Box sx={{display:'flex',backgroundColor:'#f8f9ff'}}>
<Sidebarc/>

<Box
 component="main"
 sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - 240px)` } }}
>
<ToastContainer 
position="top-right"
autoClose={5000}
newestOnTop={false}
closeOnClick
rtl={false}
hideProgressBar
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<Box sx={{marginTop:3}}>
<Cheakin data={this.state.page}/>
<Paper elevation={1} sx={{minHeight:600}}> 
<Box sx={{marginLeft:{xs:'1%',sm:'3%'},marginRight:{xs:'1%',sm:'3%'}}}>

<Box sx={{marginLeft:2,marginRight:2}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6} md={6}>
   <Box sx={{display:'flex',justifyContent:'center',}}>
   <TextField
size='small'
sx={{backgroundColor:'#f8f9ff',borderRadius:2,padding:0.5,"& input::placeholder": {
    fontSize: "13px"
  }}}
  name="search"
fullWidth
        id="input-with-icon-textfield"
        onChange={this.handleChange}
        placeholder='Task Name'
        InputProps={{
            disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{color:'#a2a2a6'}} />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
   </Box>
  </Grid>


  <Grid item xs={12} sm={6} md={6}>
   <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'right'},flexDirection:'row',marginLeft:{xs:0,sm:3}}}>
  
  
   <CSVLink data={main_arr} filename='Boroplast.csv' > 
   <Button  variant='contained'  disableElevation startIcon={<DriveFileMoveIcon />} size='small' sx={{textTransform:'none',backgroundColor:'#e26511',marginRight:1}}>
Export
</Button>
</CSVLink>

<Button variant='contained' onClick={()=>this.props.navigate('/task/add')} disableElevation startIcon={<AddIcon />} size='small' sx={{textTransform:'none',backgroundColor:'#e26511',mr:1}}>
Add Task
</Button>

<Button variant='contained' onClick={()=>this.props.navigate('/task/multiTask')} disableElevation size='small' sx={{textTransform:'none',backgroundColor:'#e26511',mr:1}}>
Multi Task
</Button>
   </Box>
  </Grid>
  </Grid>
</Box>





<Box sx={{marginTop:4,marginLeft:2,marginRight:2}}>
<Grid container spacing={1} item>
<Grid item xs={12} sm={3} md={3}>
   <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'right'},flexDirection:'row',}}>
   <TextField
    name='name_maintain_b'
label='Type'
sx={{mb:2,"& input::lebel": {
  fontSize: "13px"
}}}
value={this.state.name_maintain_b}
defaultValue={this.state.name_maintain_b}
InputLabelProps={{ style: { fontSize: 15,  } }}
onChange={this.handleChange}
    select
    fullWidth
    size='small'
    >

<MenuItem key='1' value=""  onClick={()=>this.setState({service_type_a_maintainance:"",name_maintain_b:""})} >
        Select -----
 </MenuItem> 
        {[{id:1,value:true,name:'Maintainance'},{id:2,value:false,name:'Installation'}].map((option)=>(
        <MenuItem key={option.id} value={option.value}  onClick={()=>this.setState({service_type_a_maintainance:option.value,name_maintain_b:option.name})} >
        {option.name}
        </MenuItem> 
        ))}
    </TextField>
   </Box>
</Grid>


<Grid item xs={12} sm={3} md={3}>
   <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'right'},flexDirection:'row'}}>
   <TextField
    name='area_working'
    InputLabelProps={{ style: { fontSize: 15,  } }}
    label='Area'
    value={this.state.area_working}
    defaultValue={this.state.area_working}
    select
    onChange={this.handleChange}
    fullWidth
    sx={{mb:2}}
    size='small'
    >

<MenuItem key='1' value=""  onClick={()=>this.setState({area_working:""})} >
        Select -----
 </MenuItem> 

        {this.state.areaArray.map((option)=>(
        <MenuItem key={option.area_id} value={option.area_name}  onClick={()=>this.setState({area_working:option.area_name})} >
        {option.area_name}
        </MenuItem> 
        ))}
    </TextField>
   </Box>
</Grid>


<Grid item xs={12} sm={3} md={3}>
   <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'right'},flexDirection:'row'}}>
   <TextField
    name='assigne_to_user'
    label='User'
    value={this.state.assigne_to_user}
    defaultValue={this.state.assigne_to_user}
    onChange={this.handleChange}
    select
    InputLabelProps={{ style: { fontSize: 15,  } }}
    fullWidth
    sx={{mb:2}}
    size='small'
    >
<MenuItem key='1' value=""  onClick={()=>this.setState({assigne_to_user:""})} >
        Select -----
 </MenuItem> 

        {this.state.userArray.map((option)=>(
        <MenuItem key={option.user_id} value={option.username}  onClick={()=>this.setState({assigne_to_user:option.username})} >
        {option.username}
        </MenuItem> 
        ))}
    </TextField>
   </Box>
</Grid>



<Grid item xs={12} sm={3} md={3}>
   <Box sx={{display:'flex',justifyContent:{xs:'left',sm:'left'},flexDirection:'row'}}>

<Tooltip title='Clear Filter'>
   <Box onClick={()=>this.setState({service_type_a_maintainance :"",
area_working:"",
assigne_to_user:"",name_maintain_b:"", start_date:new Date('July 20, 1970 00:20:18'),end_date:new Date('July 20, 2050 00:20:18')})} sx={{marginLeft:{xs:0,sm:1},height:40,width:40,backgroundColor:'#05507a',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:1}}>
<CloseIcon  sx={{color:'red'}}/>
 </Box>
</Tooltip>

 <Box onClick={()=>this.setState({opencalender:true})} sx={{marginLeft:{xs:2,sm:1},height:40,width:40,backgroundColor:'#05507a',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:1}}>
<CalendarMonthIcon  sx={{color:'white'}}/>
 </Box>

<Box sx={{display:moment(this.state.start_date).valueOf() === moment(new Date('July 20, 1970 00:20:18')).valueOf()? 'none':'flex',flexDirection:'column',marginLeft:1}}>
<Typography sx={{fontSize:12,fontWeight:'600'}}>Start Date :  {moment(this.state.start_date).format('DD MMM YYYY')}</Typography>
<Divider/>
<Typography sx={{fontSize:12,fontWeight:'600'}}>End  Date :  {moment(this.state.end_date).format('DD MMM YYYY')}</Typography>
</Box>


   </Box>
</Grid>




</Grid>
</Box>











<Box sx={{ overflow: "auto" }}>
<Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
<Box sx={{minHeight:400,marginTop:1}}>
<Box sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={this.state.selected.length} selected={this.state.selected} call={this.instantUpdate} />
        <TableContainer sx={{minHeight:'10vh'}}>
          <Table
            sx={{ minWidth: 1250 }}
            aria-labelledby="tableTitle"
            size={true ? 'small' : 'small'}
          >
            <EnhancedTableHead
              numSelected={this.state.selected.length}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.taskArray.length}
            />
            <TableBody>
              {m.map((row, index) => {
                const isItemSelected = this.isSelected(row.task_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                   // onClick={(event) => this.handleClick(event, row.task_id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.task_is}
                    
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer',backgroundColor:'white' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                  color='primary'
                    
                        onClick={(event) => this.handleClick(event, row.task_id)}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align='left'
                    >
                      {this.state.page * this.state.rowsPerPage +  index + 1}
                    </TableCell>
                      <TableCell align="left">{row.task_title}</TableCell>
                    <TableCell align="left">{moment(row.task_execution_date).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="left">{row.task_end_date==null?'.....' :  moment(row.task_end_date).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="left">{row.task_area}</TableCell>
                    <TableCell align="left">{row.is_maintainance?'Maintenance':'Installation'}</TableCell>
                    <TableCell align="left">{row.task_assigned_user_name==""?'....':row.task_assigned_user_name}</TableCell>
                    <TableCell align="center"><Box sx={{backgroundColor:row.task_status==='To do'?'#ebebf5' : row.task_status==='On Going'?'#fff0e6' : row.task_status==='Complete'?'#ccf1cd':row.task_status==='Cancel'?'#f79294':"white",borderRadius:3}}> <Typography sx={{padding:0.5,fontSize:12,color:row.task_status==='To do'?'blue' : row.task_status==='On Going'?'#fe964a' : row.task_status==='Complete'?'green':row.task_status==='Cancel'?'red':"white" }}>{row.task_status}</Typography></Box></TableCell>
                    <TableCell align="left">   <Tooltip title="edit"><BorderColorIcon sx={{color:'green',height:16,width:16}} onClick={this.edit.bind(this,row)}/></Tooltip> {row.task_status==='Complete'? <Tooltip title="Report for admin"> <DownloadIcon sx={{color:'orange',height:16,width:16 }} onClick={()=>{
if(row.is_maintainance==true){


this.setState({backdrop_open:true})
fetch(`${base_url.base_url}/admin_get_activity`, {
                    headers:{
                      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'content-type':'application/json'
                    },
                        method: "post",
                        body:JSON.stringify({
                       user_id:row.task_assigned_user_id,
                       task_id:row.task_id,
                        })
                      }).then((response) => { 
                        if(response.status==200){
                           response.json().then((data)=> {
                 
                            fetch(`${base_url.base_url}/createInvoice`,
                            {  method: 'POST',
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                            },
                               body: JSON.stringify({
                                 alldata:row,
                                 activityArray:data.data
                        
                               }),
                             })
                                 .then((response) => response.json())
                                 .then(async(dataa) =>
                                 {
                                  this.setState({backdrop_open:false})
                                 window.open(`${base_url.base_url}/${dataa.path}.pdf`)
                                
                                 })
                                 .catch((error) => console.error(error))




                          });
                       } })

                      }else{

                        this.setState({backdrop_open:true})
                        fetch(`${base_url.base_url}/admin_get_activity`, {
                                            headers:{
                                              'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                                            'content-type':'application/json'
                                            },
                                                method: "post",
                                                body:JSON.stringify({
                                               user_id:row.task_assigned_user_id,
                                               task_id:row.task_id,
                                                })
                                              }).then((response) => { 
                                                if(response.status==200){
                                                   response.json().then((data)=> {
                                         
                                                    fetch(`${base_url.base_url}/billforatm/`,
                                                    {  method: 'POST',
                                                    headers: {
                                                      Accept: 'application/json',
                                                      'Content-Type': 'application/json',
                                                    },
                                                       body: JSON.stringify({
                                                         alldata:row,
                                                         activityArray:data.data
                                                
                                                       }),
                                                     })
                                                         .then((response) => response.json())
                                                         .then(async(dataa) =>
                                                         {
                                                          this.setState({backdrop_open:false})
                                                         window.open(`${base_url.base_url}/${dataa.path}.pdf`)
                                                        
                                                         })
                                                         .catch((error) => console.error(error))
                        
                        
                        
                        
                                                  });
                                               } })



                      }




                    }}/></Tooltip>:null
                  
                  
                  }
                    
                    


                    {row.task_status==='Complete'? <Tooltip title="Report for corporate"> <DownloadIcon sx={{color:'#8e84f3',height:16,width:16 }} onClick={()=>{

if(row.is_maintainance===true){


this.setState({backdrop_open:true})
fetch(`${base_url.base_url}/admin_get_activity`, {
                    headers:{
                      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'content-type':'application/json'
                    },
                        method: "post",
                        body:JSON.stringify({
                       user_id:row.task_assigned_user_id,
                       task_id:row.task_id,
                        })
                      }).then((response) => { 
                        if(response.status==200){
                           response.json().then((data)=> {
                 
                            fetch(`${base_url.base_url}/createInvoiceforcorpporate/`,
                            {  method: 'POST',
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                            },
                               body: JSON.stringify({
                                 alldata:row,
                                 activityArray:data.data
                        
                               }),
                             })
                                 .then((response) => response.json())
                                 .then(async(dataa) =>
                                 {
                                  this.setState({backdrop_open:false})
                                 window.open(`${base_url.base_url}/${dataa.path}.pdf`)
                                
                                 })
                                 .catch((error) => console.error(error))
                          });
                       } })

                      }else{
                        this.setState({backdrop_open:true})
                        fetch(`${base_url.base_url}/admin_get_activity`, {
                                            headers:{
                                              'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                                            'content-type':'application/json'
                                            },
                                                method: "post",
                                                body:JSON.stringify({
                                               user_id:row.task_assigned_user_id,
                                               task_id:row.task_id,
                                                })
                                              }).then((response) => { 
                                                if(response.status==200){
                                                   response.json().then((data)=> {
                                         
                                                    fetch(`${base_url.base_url}/billforatm/`,
                                                    {  method: 'POST',
                                                    headers: {
                                                      Accept: 'application/json',
                                                      'Content-Type': 'application/json',
                                                    },
                                                       body: JSON.stringify({
                                                         alldata:row,
                                                         activityArray:data.data
                                                
                                                       }),
                                                     })
                                                         .then((response) => response.json())
                                                         .then(async(dataa) =>
                                                         {
                                                          this.setState({backdrop_open:false})
                                                         window.open(`${base_url.base_url}/${dataa.path}.pdf`)
                                                        
                                                         })
                                                         .catch((error) => console.error(error))
                                                  });
                                               } })
                  }

                    }}/></Tooltip>:null
              }

                    <Tooltip title="Delete">
                     <DeleteIcon sx={{color:'red',height:16,width:16, marginLeft:0.5,marginRight:0.5}} onClick={() =>this.setState({deleted_task_id:row.task_id},()=>{this.setState({open_for_delete:true})}) }/> 
                     </Tooltip>
                     
                        </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (true ? 33 : 33) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10,20, 25]}
          component="div"
          count={main_arr.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Box>


</Box>
</Box>
</Box>


</Box>
</Paper>
</Box>
</Box>
</Box>






<Box sx={{width:'100%'}}>
<Modal
style={{display:'flex',justifyContent:'center',alignItems:'center'}}
  open={this.state.openmodel}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Paper elevation={1} sx={{maxHeight:{xs:'90vh',sm:'80vh'},width:{xs:'95%',sm:'80%'},overflow:'scroll'}}>
    <Paper onClick={()=>this.setState({openmodel:false})} elevation={5} sx={{height:30,width:30,backgroundColor:'#e5e2fc',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <CloseIcon color='#1c446a' />
    </Paper>  
  <Box sx={{marginTop:5}}>


  <Box sx={{padding:{xs:2,sm:4}}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
  <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Title<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
  <TextField
    name='task_title'
    value={this.state.task_title}
    fullWidth
    onChange={this.handleChange}
    size='small'
    sx={{mb:2}}
    />
<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Service Type<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
   value={this.state.is_installation?'Installation':'Maintenance'}
    select
    fullWidth
    sx={{mb:2}}
    size='small'
    >
        {[{id:1,value:true,name:'Maintenance'},{id:2,value:true,name:'Installation'}].map((option)=>(
        <MenuItem key={option.state_id} value={option.name} onClick={this.servicetype.bind(this,option)} >
        {option.name}
        </MenuItem> 
        ))}
    </TextField>
  </Grid>
  <Grid item xs={12} sm={6}>
    <Box sx={{width:'100%'}}>
  <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Description<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextareaAutosize
  aria-label="Description"
  minRows={7} 
  name='description'
  value={this.state.description}
onChange={this.handleChange}
  placeholder="Description"
  style={{borderRadius:5,borderColor:'#e5e2fc' ,mb:2,marginRight:10,width:'100%'}}
/>
</Box>
  </Grid>
</Grid>
</Box>





{
this.state.is_installation?
<Box sx={{marginLeft:{xs:2,sm:4},marginRight:{xs:2,sm:4}}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
  <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Select Product<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='product_name'
    value={this.state.product_name}
    fullWidth
    onChange={this.handleChange}
    sx={{mb:2}}
    size='small'
    select
    >
{product_array.map((option)=>(
        <MenuItem key={option.product_id} value={option.productname} onClick={()=>this.setState({product_id:option.product_id,product_name:option.productname})} >
        {option.productname}
        </MenuItem> 
        ))}
    </TextField>

    <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>state<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
    <TextField
     sx={{mb:2}}
    name='state_name'
    onChange={this.handleChange}
    value={this.state.state_name}
    fullWidth
    size='small'
    />

 <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>City<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
 onChange={this.handleChange}
 value={this.state.city_name}
    name='city_name'
    fullWidth
    size='small'
    />

<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Execution Date<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
 type='datetime-local'
 onChange={this.handleChange}
 value={moment(this.state.execution_date).format('YYYY-MM-DD hh:ss')}
    name='execution_date'
    fullWidth
    size='small'
    />

<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Status<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='status'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.status}
    onChange={this.handleChange}
    size='small'
    >
        {[{id:0,name:'To do',date:Date()},{id:1,name:'On Going',date:Date()},{id:2,name:'Complete',date:Date()},{id:3,name:'Cancel',date:Date()}].map((option)=>(
        <MenuItem key={option.id} value={option.name} onClick={()=>{
          if(option.id==0){
            this.setState({
              cancel_date:null,
              on_going_date:null,
              task_end_date:null
            })
          }
          
          if(option.id==1){
            this.setState({
              cancel_date:null,
              on_going_date:Date(),
              task_end_date:null
            })
          }
          
          if(option.id==2){
            this.setState({
              cancel_date:null,
              on_going_date:null,
              task_end_date:Date()
            })
          }
          
          if(option.id==3){
            this.setState({
              cancel_date:Date(),
              on_going_date:null,
              task_end_date:null
            })
          }
          
        }}>
        {option.name}
        </MenuItem> 
        ))}
    </TextField>

    <Typography sx={{fontSize:12,display:'none',flexDirection:'row',fontWeight:'700'}}>Latitude<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2,display:'none'}}
    name='latitude'
    onChange={this.handleChange}
    value={this.state.latitude}
    fullWidth
    size='small'
    />

<Typography sx={{fontSize:12,display:'none',flexDirection:'row',fontWeight:'700'}}>Longitude<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2,display:'none'}}
    name='longitude'
    onChange={this.handleChange}
    value={this.state.longitude}
    fullWidth
    size='small'
    />
  </Grid>
  <Grid item xs={12} sm={6}>
  <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Address<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
    name='address'
    onChange={this.handleChange}
    value={this.state.address}
    fullWidth
    size='small'
    />
<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Area<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
    name='area_name'
    onChange={this.handleChange}
    value={this.state.area_name}
    fullWidth
    size='small'
    />


<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Assigned To<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='user_name'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.user_name}
    onChange={this.handleChange}
    size='small'
    >
        {user_array.map((option)=>(
        <MenuItem key={option.user_id} value={option.username} onClick={()=>this.setState({user_id:option.user_id,user_name:option.username})} >
        {option.username}
        </MenuItem> 
        ))}
    </TextField>



<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Select Collaborator<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='callaboration_user_name'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.callaboration_user_name}
    onChange={this.handleChange}
    size='small'
    >
      <MenuItem key='1' value="" onClick={()=>this.setState({callaboration_user_id:"",callaboration_user_name:""})} >
       Select Collaborator--
        </MenuItem> 
        {user_array.map((option)=>(
        <MenuItem key={option.user_id} value={option.username} onClick={()=>this.setState({callaboration_user_id:option.user_id,callaboration_user_name:option.username})} >
        {option.username}
        </MenuItem> 
        ))}
    </TextField>

</Grid>
</Grid>
</Box>:null

}


{
  this.state.is_maintainance?

<Box sx={{paddingLeft:{xs:2,sm:4},paddingRight:{xs:2,sm:4}}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6}>

<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Select Product<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='product_name'
    value={this.state.product_name}
    fullWidth
    onChange={this.handleChange}
    sx={{mb:2}}
    size='small'
    select
    >
{product_array.map((option)=>(
        <MenuItem key={option.product_id} value={option.productname} onClick={()=>this.setState({product_id:option.product_id,product_name:option.productname})} >
        {option.productname}
        </MenuItem> 
        ))}
    </TextField>



    <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Select Corporate<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='corporate_name'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.corporate_name}
    onChange={this.handleChange}
    size='small'
    >
        {this.state.corporateArray.map((option)=>(
        <MenuItem key={option.corporate_id} value={option.corporate_name} onClick={()=>this.setState({corporate_id:option.corporate_id,corporate_name:option.corporate_name})} >
        {option.corporate_name}
        </MenuItem> 
        ))}
    </TextField>
  



<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Select Unit<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='unit_name'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.unit_name}
    onChange={this.handleChange}
    size='small'
    >
        {unit_array.map((option)=>(
        <MenuItem key={option.unit_id} value={option.unit_name} onClick={()=>this.setState({unit_id:option.unit_id,unit_name:option.unit_name,state_name:option.unit_state_name,city_name:option.unit_city_name,area_name:option.unit_Area_name,address:option.unit_address,latitude:option.latitude,longitude:option.longitude,   })} >
        {option.unit_name}
        </MenuItem> 
        ))}
    </TextField>
  

    <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>state<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
    <TextField
     sx={{mb:2}}
    name='state_name'
    value={this.state.state_name}
    fullWidth
    size='small'
    />

 <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>City<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
 value={this.state.city_name}
    name='city_name'
    fullWidth
    size='small'
    />


<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Latitude<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
    name='latitude'
   // onChange={this.handleChange}
    value={this.state.latitude}
    fullWidth
    size='small'
    />

<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Longitude<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
    name='longitude'
   // onChange={this.handleChange}
    value={this.state.longitude}
    fullWidth
    size='small'
    />



  </Grid>
<Grid  item xs={12} sm={6}>
<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Assigned To<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='user_name'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.user_name}
    onChange={this.handleChange}
    size='small'
    >
        {user_array.map((option)=>(
        <MenuItem key={option.user_id} value={option.username} onClick={()=>this.setState({user_id:option.user_id,user_name:option.username})} >
        {option.username}
        </MenuItem> 
        ))}
    </TextField>



<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Select Collaborator<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='callaboration_user_name'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.callaboration_user_name}
    onChange={this.handleChange}
    size='small'
    >
      <MenuItem key='1' value="" onClick={()=>this.setState({callaboration_user_id:"",callaboration_user_name:""})} >
       Select Collaborator--
        </MenuItem> 
        {calloboration_array.map((option)=>(
        <MenuItem key={option.user_id} value={option.username} onClick={()=>this.setState({callaboration_user_id:option.user_id,callaboration_user_name:option.username})} >
        {option.username}
        </MenuItem> 
        ))}
    </TextField>



    <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Address<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
    name='address'
    value={this.state.address}
    fullWidth
    size='small'
    />
<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Area<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
    name='area_name'
    value={this.state.area_name}
    fullWidth
    size='small'
    />


<Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Status<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
    name='status'
    fullWidth
    sx={{mb:2}}
    select
    value={this.state.status}
    onChange={this.handleChange}
    size='small'
    >
        {[{id:0,name:'To do',date:Date()},{id:1,name:'On Going',date:Date()},{id:2,name:'Complete',date:Date()},{id:3,name:'Cancel',date:Date()}].map((option)=>(
        <MenuItem key={option.id} value={option.name} onClick={()=>{
          if(option.id==0){
            this.setState({
              cancel_date:null,
              on_going_date:null,
              task_end_date:null
            })
          }
          
          if(option.id==1){
            this.setState({
              cancel_date:null,
              on_going_date:Date(),
              task_end_date:null
            })
          }
          
          if(option.id==2){
            this.setState({
              cancel_date:null,
              on_going_date:null,
              task_end_date:Date()
            })
          }
          
          if(option.id==3){
            this.setState({
              cancel_date:Date(),
              on_going_date:null,
              task_end_date:null
            })
          }
          
        }}>
        {option.name}
        </MenuItem> 
        ))}
    </TextField>


    <Typography sx={{fontSize:12,display:'flex',flexDirection:'row',fontWeight:'700'}}>Execution Date<Typography sx={{color:'red',fontSize:14}}>*</Typography></Typography>
<TextField
 sx={{mb:2}}
 type='datetime-local'
 onChange={this.handleChange}
 value={moment(this.state.execution_date).format('YYYY-MM-DD hh:ss')}
    name='execution_date'
    fullWidth
    size='small'
    />

</Grid>
</Grid>
</Box>:null
}

<Box sx={{display:'flex',justifyContent:'right',marginRight:3}}>
<Button size='small' variant='contained' onClick={this.submit} sx={{textTransform:'none',backgroundColor:'#e26511'}}>Submit</Button>
</Box>

<br/>
<br/>
</Box>
  </Paper>
</Modal>
</Box>







<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdrop_open}
>
  <CircularProgress color="inherit" />
</Backdrop>







 <Dialog
  open={this.state.opencalender}
  onClose={this.handleClose}
sx={{width:'100%'}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
  <DateRangePicker
        ranges={[selectionRange]}
        onChange={this.handleSelect}
      />
</Box>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'right',padding:1}}>
<Button variant='contained' size='small' onClick={()=>this.setState({opencalender:false})} sx={{marginRight:3,textTransform:'none',backgroundColor:'#05507a'}}>Cancel</Button>
<Button variant='contained' size='small' onClick={this.apply} sx={{textTransform:'none',backgroundColor:'#05507a'}}>Apply</Button>
</Box>
</Dialog>









<Dialog
  open={this.state.open_for_delete}
  onClose={this.handleClose}
sx={{width:'100%'}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',minHeight:150,width:250}}>
 
  <DeleteForeverIcon sx={{height:50,width:50,color:'#1c446a'}}/>

<Typography sx={{fontSize:16,fontWeight:'bold',textAlign:'center',color:'#1c446a'}}>Are You sure ?</Typography>


</Box>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'right',padding:1}}>
<Button variant='contained' fullWidth disableElevation size='small' onClick={()=>this.setState({open_for_delete:false})} sx={{marginRight:3,textTransform:'none',backgroundColor:'#05507a'}}>Cancel</Button>
<Button variant='contained'fullWidth disableElevation size='small' onClick={this.delete} sx={{textTransform:'none',backgroundColor:'#05507a'}}>Ok</Button>
</Box>
</Dialog>






      </Box>
    )
  }
}

export default Task





export function Taskc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Task navigate={navigate}></Task>)
}