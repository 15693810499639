import { MenuItem, Paper, TextField ,Box, Typography} from '@mui/material';
import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import base_url from '../base';
class Linecharttt extends Component {
  constructor(props) {
    super(props);

    this.state = {

componnent_name_add:"",

activity_array:[],
component:[],

component_name_array : [],
array:[],

series: [
  {
    name: "Trend (tracked)",
    data: []
  }
]

}

    this.handleChange= this.handleChange.bind();
  }


async componentDidMount(){
 await fetch(`${base_url.base_url}/retrive_activity_array_for_admin_graaph`, {
  headers:{
'authorization': `Bearer ${sessionStorage.getItem('token')}`,
  'content-type':'application/json'
  },
      method: "post",
      body:JSON.stringify({})
    }).then((response) => { 
      if(response.status==200){
         response.json().then(async(data)=> {
          this.setState({activity_array:data.data})
         console.log(data.data)
         })
       .then(async()=>{
  await fetch(`${base_url.base_url}/retriveComponent`, {
          headers:{
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'content-type':'application/json'
          },
              method: "post",
              body:JSON.stringify({})
            }).then((response) => { 
              if(response.status==200){
                 response.json().then(async(data)=> {
                  this.setState({component:data.data},()=>{
 this.ss();

/*
                    let data_container_for_value = [];
                    let int_quantity = 0;
                    let component_name = []
                    
                    if(1===1){
                      console.log('running')
                    for (let i = 0; i < this.state.component.length; i++) {
                      
                    this.state.component_name_array.push(this.state.component[i].component_name);
                    console.log( ' dhhj')
                      data_container_for_value.push({quentity:int_quantity,component_name:this.state.component[i].component_name});
                      int_quantity = 0;
                    for (let j = 0; j < this.state.activity_array.length; j++) {
                     if(this.state.component[i].component_id===this.state.activity_array[j].component_id){
                     
                    int_quantity = int_quantity + parseInt(this.state.activity_array[j].quentity);
                     }
                    }
                    }}
                   // var topValues = data_container_for_value.sort((a,b) => b-a).slice(0,10);
                    this.setState({array:data_container_for_value},()=>{
                     var topValues = this.state.array.sort((a,b) => b.quentity-a.quentity).slice(0,10);
                    
                    let comp_data = [];
                    let quantity_dd = [];
                    for (let i = 0; i < topValues.length; i++) {
                      comp_data.push(topValues[i].component_name);
                      quantity_dd.push(topValues[i].quentity);
                    }

                       this.setState({
                      series : [
                          {
                            name: "Trend (tracked)",
                            data: quantity_dd
                          }
                        ],
                        component_name_array:comp_data
                       })
                      
                    });
                    
*/






                  },()=>{
                   
                  })
                 })
                }}).then(async()=>{
                }).then(()=>{
                 
                })
        })



      }})


                  







/*

  this.setState({
    series: [{
      name: "Trend (tracked)",
      data: data_container_for_value
    }]
  });
*/

}



ss =()=>{
 let data_container_for_value = [];
                    let int_quantity = 0;
                    let component_name = []
                    
                    if(1===1){
                      console.log('running')
                    for (let i = 0; i < this.state.component.length; i++) {
                    this.state.component_name_array.push(this.state.component[i].component_name);
                    
                      data_container_for_value.push({quentity:int_quantity,component_name:this.state.component[i].component_name});
                      int_quantity = 0;
                     
                      //let activity_filter = this.state.activity_array.filter((e=>(e.component_id =="" || e.component_id.includes(this.state.component[i].component_id)) && (e.component=="" || e.component.includes(this.state.componnent_name_add) )     ))

                    for (let j = 0; j < this.state.activity_array.length; j++) {
                     if(this.state.component[i].component_id===this.state.activity_array[j].component_id){
                    int_quantity = int_quantity + parseInt(this.state.activity_array[j].quentity);
                     }
                    }
                    }}
                   // var topValues = data_container_for_value.sort((a,b) => b-a).slice(0,10);
                    this.setState({array:data_container_for_value},()=>{
                     var topValues = this.state.array.sort((a,b) => b.quentity-a.quentity).slice(0,7);
                    
                    let comp_data = [];
                    let quantity_dd = [];
                    for (let i = 0; i < topValues.length; i++) {
                      comp_data.push(topValues[i].component_name);
                      quantity_dd.push(topValues[i].quentity);
                    }

                       this.setState({
                      series : [
                          {
                            name: "Trend (tracked)",
                            data: quantity_dd
                          }
                        ],
                        component_name_array:comp_data
                       })
                      
                    });
                    




}






handleChange=(e)=>{
  this.setState({[e.target.name]:e.target.value});
}





  render() {

   let options = {
      chart: {
        id: 'apexchart-example'
      },
      xaxis: {
        categories:this.state.component_name_array
      }
    }


    console.log(this.state.array)


    return (
      <Paper sx={{minHeight:300}}>
        <Chart options={options} series={this.state.series} type="area" width='100%' height={320} />
      </Paper>
      
    )
  }
}

export default Linecharttt





