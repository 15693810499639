import React, { Component } from 'react'
import { useNavigate,useLocation,useMatch } from 'react-router-dom';

import { Box, Button, Divider, Grid, MenuItem, Paper, TextField, Typography, alertTitleClasses } from '@mui/material';
import Appheader from '../Projectfile/Appheader';
import { Sidebarc } from '../Projectfile/Sidebar';
import Cheakin from '../Projectfile/Cheakin'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import base_url from '../base';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {InputAdornment,Radio,TablePagination,Modal} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { alpha } from '@mui/material/styles';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactPageIcon from '@mui/icons-material/ContactPage';


export class Stock extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       userdata:[],   ////// handling user data 
       componentArray:[],   ///// handling component array  for daa
      

 user_name:"",
 user_id:"",

 /////////////////////
 quentity:0,
 component:"",
 component_id:"",



 allDataAssigned:[],

    }
    this.handleChange = this.handleChange.bind(this)
  }
handleChange=(e)=>{
  this.setState({[e.target.name]:e.target.value});
}




componentDidMount(){

    fetch(`${base_url.base_url}/retriveUsers`, {
        headers:{
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
        'content-type':'application/json'
        },
            method: "post",
            body:JSON.stringify({})
          }).then((response) => { 
            if(response.status==200){
               response.json().then((data)=> {
      this.setState({userdata:data.data})
              });
           } })



           fetch(`${base_url.base_url}/retriveComponent`, {
            headers:{
              'authorization': `Bearer ${sessionStorage.getItem('token')}`,
            'content-type':'application/json'
            },
                method: "post",
                body:JSON.stringify({})
              }).then((response) => { 
                if(response.status==200){
                   response.json().then((data)=> {
          this.setState({componentArray:data.data})
                  });
               } })
           
}


submit=()=>{
  fetch(`${base_url.base_url}/addstock`, {
    headers:{
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
    'content-type':'application/json'
    },
        method: "post",
        body:JSON.stringify({


        list : this.state.allDataAssigned,
        user_name:this.state.user_name,
        user_id:this.state.user_id,



        })
      }).then((response) => { 
        if(response.status==200){
           response.json().then((data)=> {
            this.setState({
              userdata:[],   ////// handling user data 
              componentArray:[],   ///// handling component array  for daa
        user_name:"",
        user_id:"",
      
        quentity:"",
        component:"",
        component_id:"",
        allDataAssigned:[],

            })
           
            this.props.navigate('/stock')

          });
       } })
}

done = ()=>toast.error("All fields are compulsory")
deletePopup = () => toast.success("Deleted Succesfully")
updatePopup = () => toast.success("Insertion Succesfull")
erorPopup =()=> toast.error("All Fields are Compulsory",{
  theme: "colored"
})



deleteById=(data)=>{
  
  const arr = this.state.allDataAssigned.filter((item) => item.id !== data);
  this.setState({allDataAssigned:arr})
  
}


  render() {
    return (
      <Box sx={{backgroundColor:'#f8f9ff'}}>
      <Appheader/>
      <br/>
    <Box sx={{display:'flex',backgroundColor:'#f8f9ff'}}>
    <Sidebarc/>
    <Box
     component="main"
     sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - 240px)` } }}
    >
      <Box sx={{marginTop:3}}>
<Cheakin data={this.state.page}/>
<Paper elevation={1} sx={{minHeight:600}}> 
<Typography sx={{fontSize:{xs:16,sm:20},fontWeight:'400',padding:2}}>Add Stock </Typography>
<Divider/>

<ToastContainer 
position="top-right"
autoClose={5000}
newestOnTop={false}
closeOnClick
rtl={false}
hideProgressBar
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>


<Box sx={{display:'column',justifyContent:'center',marginTop:0.5,marginLeft:{xs:'4%',sm:'20%'},marginRight:{xs:'4%',sm:'20%'}}}>
<Typography sx={{display:'flex',marginTop:5,fontSize:12,fontWeight:'600'}} >Select User<Typography sx={{color:'red',fontSize:15}}>*</Typography> </Typography>
<TextField
  sx={{"& input::placeholder": {
    fontSize: "13px"
  },marginBottom:2}}
  size='small'
  onChange={this.handleChange}
  value={this.state.user_name}
  fullWidth
  select
  placeholder='select User'
  name="user_name"
  >
{
      this.state.userdata.map((option) => (
        <MenuItem key={option.user_id} value={option.username} onClick={()=>this.setState({user_name:option.username,user_id:option.user_id})}>
          {option.username}
        </MenuItem>
      ))}
  </TextField>

 <Grid container item spacing={2}>
    <Grid xs={12} item sm={6}>
    <Typography sx={{display:'flex',marginTop:0.5,fontSize:12,fontWeight:'600'}} >Select Component<Typography sx={{color:'red',fontSize:15}}>*</Typography> </Typography>
    <TextField
  sx={{"& input::placeholder": {
    fontSize: "13px"
  }}}
  size='small'
  onChange={this.handleChange}
  value={this.state.component}
  fullWidth
  select
  placeholder='Select Component'
  name="component"
  >
{
      this.state.componentArray.map((option) => (
        <MenuItem key={option.component_id} value={option.component_name} onClick={()=>this.setState({component:option.component_name,component_id:option.component_id})}>
          {option.component_name}
        </MenuItem>
      ))}
  </TextField>
    </Grid>
    


    <Grid xs={12} item sm={6}>

    <Typography sx={{display:'flex',marginTop:0.5,fontSize:12,fontWeight:'600'}} >Quantity<Typography sx={{color:'red',fontSize:15}}>*</Typography> </Typography>
    <TextField
  sx={{"& input::placeholder": {
    fontSize: "13px"
  }}}
  size='small'
  onChange={this.handleChange}
  value={this.state.quentity}
  fullWidth
  type='number'
  placeholder='Enter Quantity'
  name="quentity"
  />
   </Grid>
 </Grid>
 
 

 <Box sx={{display:'flex',flexDirection:'row',justifyContent:'Right'}}>
<Button variant='contained' size='small' disableElevation sx={{backgroundColor:'#1c446a',marginTop:2,borderRadius:5,textTransform:'none'}} onClick={()=>{
  if(this.state.component!=="" && this.state.quentity!=="" && this.state.user_name!==""){
     this.setState(
    prevState => ({
      allDataAssigned: [...prevState.allDataAssigned, {id:Date.now().toString(36) + Math.random().toString(36),component:this.state.component,component_id:this.state.component_id,quentity:this.state.quentity}] 
    })
  )
  }else{
   this.done();
  }
 
  //this.updatePopup();
}}>
Add
</Button>
 </Box>

</Box>









<Box sx={{marginLeft:{xs:'4%',sm:'20%'},marginRight:{xs:'4%',sm:'20%'},marginTop:4}}>
<Typography sx={{fontWeight:'600',fontSize:16,color:'#c1c1c1',borderColor:'#c1c1c1',marginBottom:2}}>Added Component and Quantity</Typography>
<TableContainer>
      <Table  size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Component Name</TableCell>
            <TableCell align="center" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Quantity</TableCell>
            <TableCell align="right" sx={{color:'#718096',fontSize:14,fontWeight:'500'}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.allDataAssigned.map((row) => (
            <TableRow
              key={row.id}
              sx={{border:0}}
            >
              <TableCell align="left" sx={{fontSize:13,}}>{row.component}</TableCell>
              <TableCell align="center" sx={{fontSize:13,}}>{row.quentity}</TableCell>
              <TableCell align="right"> <DeleteIcon sx={{color:'red',height:16,width:16, marginLeft:0.5,marginRight:0.5}} onClick={()=>{
const arr = this.state.allDataAssigned.filter((item) => item.id !== row.id);
this.setState({allDataAssigned:arr});
this.deletePopup();
              }}/> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
<br/>
<br/>
<Box sx={{display:this.state.allDataAssigned.length > 0 ?'flex': 'none',justifyContent:'right',width:'100%' ,}}>
<Button variant='contained' size='small' sx={{backgroundColor:'#05507a',borderRadius:7,textTransform:'none'}} onClick={this.submit}>
  submit
</Button>
</Box>
</Box>

<br/>



</Paper>
  </Box>
    </Box>
    </Box>





    </Box>
    )
  }
}

export default Stock
export function Stockc(props){
    const navigate = useNavigate();
    const location = useLocation();
    return (<Stock location={location} navigate={navigate}></Stock>)
  }
  